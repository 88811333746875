<script setup lang="ts">
import type { SliceInterface, VoixMediaFieldInterface, VoixNumberFieldInterface, VoixSelectFieldInterface, VoixTextareaFieldInterface, VoixTextFieldInterface } from '@voix/types'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const props = defineProps<{
  inView: boolean
  slice: SliceInterface
  fields: {
    colspan: VoixNumberFieldInterface
    title: VoixTextFieldInterface
    description: VoixTextareaFieldInterface
    textAlign: VoixSelectFieldInterface
    image: VoixMediaFieldInterface
    fadeHeight: VoixNumberFieldInterface
    topLeftGradient: VoixSelectFieldInterface
    topGradient: VoixSelectFieldInterface
    topRightGradient: VoixSelectFieldInterface
    rightGradient: VoixSelectFieldInterface
    bottomRightGradient: VoixSelectFieldInterface
    bottomGradient: VoixSelectFieldInterface
    bottomLeftGradient: VoixSelectFieldInterface
    leftGradient: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Generic Bento Card', group: 'Bento', layouts: ['VoixMarketing'] },
  slots: [{ name: 'default', allowedElements: [] }],
  description: '',
  preview: 'SlicesBentoGenericCard.jpg',
  fields: {
    colspan: {
      type: 'number',
      label: 'Column Span at Desktop',
      default: 4,
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    description: {
      type: 'textarea',
      label: 'Description',
    },
    textAlign: {
      type: 'select',
      label: 'Text Align',
      options: [
        { label: 'Left', value: 'text-left' },
        { label: 'Center', value: 'text-center' },
        { label: 'Right', value: 'text-right' },
      ],
      default: 'text-left',
    },
    image: {
      type: 'media',
      label: 'Background Media',
      breakpoints: {
        default: {
          width: 470,
          height: 255,
        },
      },
    },

    fadeHeight: {
      type: 'number',
      label: 'Fade Height',
      default: 100,
      enabled: true,
    },

    topLeftGradient: {
      type: 'select',
      label: 'Top Left Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    topGradient: {
      type: 'select',
      label: 'Top Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    topRightGradient: {
      type: 'select',
      label: 'Top Right Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    rightGradient: {
      type: 'select',
      label: 'Right Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    bottomRightGradient: {
      type: 'select',
      label: 'Bottom Right Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    bottomGradient: {
      type: 'select',
      label: 'Bottom Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    bottomLeftGradient: {
      type: 'select',
      label: 'Bottom Left Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

    leftGradient: {
      type: 'select',
      label: 'Left Gradient',
      options: {
        // Grays
        'from-slate-600': 'Slate',
        'from-gray-600': 'Gray',
        'from-zinc-600': 'Zinc',
        'from-neutral-600': 'Neutral',
        'from-stone-600': 'Stone',
        // Colors
        'from-red-600': 'Red',
        'from-orange-600': 'Orange',
        'from-amber-600': 'Amber',
        'from-yellow-600': 'Yellow',
        'from-lime-600': 'Lime',
        'from-green-600': 'Green',
        'from-emerald-600': 'Emerald',
        'from-teal-600': 'Teal',
        'from-cyan-600': 'Cyan',
        'from-sky-600': 'Sky',
        'from-blue-600': 'Blue',
        'from-indigo-600': 'Indigo',
        'from-violet-600': 'Violet',
        'from-purple-600': 'Purple',
        'from-fuchsia-600': 'Fuchsia',
        'from-pink-600': 'Pink',
        'from-rose-600': 'Rose',
      },
      enabled: false,
    },

  },
  templates: [{
    label: 'Main Example',
    fields: {
      title: {
        value: 'Title',
      },
      description: {
        value: 'Description',
      },
      image: {
        value: 'https://placekitten.com/470/255',
      },
    },
  }],
})

const elements: SliceInterface[] = props.slice.elements as SliceInterface[]

const breakpoints = useBreakpoints(breakpointsTailwind)

const activated = ref(false)
provide('activated', activated)

watchEffect(() => {
  if (breakpoints.lg.value === false && props.inView) {
    setTimeout(() => {
      activated.value = true
    }, 1000)
  }
})
</script>

<template>
  <div
    class="relative border-neutral-900 border rounded-md font-sans overflow-hidden group duration-500"
    :class="[fields.textAlign.value, { 'bg-neutral-900': !activated }]"
    @mouseenter="activated = true"
  >
    <div
      class="absolute inset-0 z-0"
    >
      <div class="absolute inset-0">
        <VoixMedia :field="fields.image" class=" w-full h-full object-cover" />
      </div>
      <div class="absolute inset-0 z-10 flex items-start">
        <VoixSlice v-for="s in elements" :key="s.id" :slice="s" :slice-index="s.id" />
      </div>
    </div>

    <div
      class="absolute bottom-0 left-0 right-0 "
    >
      <div class="bg-gradient-to-t from-neutral-900 to-neutral-900/0 h-[50px] duration-500" />
      <div
        class="bg-neutral-900" :style="{
          height: `${fields.fadeHeight.value}px`,
        }"
      />
    </div>

    <div class="absolute bottom-0 left-0 right-0 z-10 p-8 ">
      <div class="text-lg font-medium">
        {{ fields.title.value }}
      </div>
      <div class="pt-1 text-sm text-neutral-400 font-light">
        {{ fields.description.value }}
      </div>
    </div>

    <div v-if="fields.topLeftGradient.enabled" class="pointer-events-none absolute z-40 top-0 w-full h-full bg-gradient-to-br via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.topLeftGradient.value]" />
    <div v-if="fields.topGradient.enabled" class="pointer-events-none absolute z-40 top-0 w-full h-full bg-gradient-to-b via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.topGradient.value]" />
    <div v-if="fields.topRightGradient.enabled" class="pointer-events-none absolute z-40 top-0 w-full h-full bg-gradient-to-bl via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.topRightGradient.value]" />
    <div v-if="fields.rightGradient.enabled" class="pointer-events-none absolute z-40 top-0 bottom-0 w-full h-full bg-gradient-to-l via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.rightGradient.value]" />
    <div v-if="fields.bottomRightGradient.enabled" class="pointer-events-none absolute z-40 bottom-0 w-full h-full bg-gradient-to-tl via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.bottomRightGradient.value]" />
    <div v-if="fields.bottomGradient.enabled" class="pointer-events-none absolute z-40 bottom-0 w-full h-full bg-gradient-to-t via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.bottomGradient.value]" />
    <div v-if="fields.bottomLeftGradient.enabled" class="pointer-events-none absolute z-40 bottom-0 w-full h-full bg-gradient-to-tr via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.bottomLeftGradient.value]" />
    <div v-if="fields.leftGradient.enabled" class="pointer-events-none absolute z-40 left-0 top-0 bottom-0 w-full h-full bg-gradient-to-r via-neutral-950/0 via-30% to-neutral-950/0 duration-1000" :class="[{ 'opacity-0': !activated, 'opacity-25': activated }, fields.leftGradient.value]" />
  </div>
</template>

<style scoped>
.span-1 {
  @apply col-span-1;
}
.span-2 {
  @apply col-span-2;
}
.span-3 {
  @apply col-span-3;
}
.span-4 {
  @apply col-span-4;
}
.span-5 {
  @apply col-span-5;
}
.span-6 {
  @apply col-span-6;
}
.span-7 {
  @apply col-span-7;
}
.span-8 {
  @apply col-span-8;
}
.span-9 {
  @apply col-span-9;
}
.span-10 {
  @apply col-span-10;
}
.span-11 {
  @apply col-span-11;
}
.span-12 {
  @apply col-span-12;
}
</style>
